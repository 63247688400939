[mat-table] {
  &.mat-mdc-table {
    @apply bg-primary;

    .mat-mdc-header-row {
      @apply h-[3rem] bg-tertiary text-primary;

      .mat-mdc-header-cell {
        @apply px-[1rem];

        .mat-sort-header-arrow {
          @apply ml-[0.5rem] text-secondary;
        }

        &.text-center {
          .mat-sort-header-container {
            @apply place-content-center;

            .mat-sort-header-content {
              @apply ml-[17px];
            }
          }
        }

        &.text-right {
          .mat-sort-header-container {
            @apply place-content-end;

            .mat-sort-header-content {
              @apply order-2;
            }

            .mat-sort-header-arrow {
              @apply ml-0 mr-[6px] order-1;
            }
          }
        }
      }
    }

    .mat-mdc-row {
      @apply h-auto text-primary;

      &:not(.no-hover) {
        @apply hover:bg-secondary;
      }

      .mat-mdc-cell {
        @apply px-[1rem] py-[0.5rem] border-b-light align-top;
      }

      &:last-child {
        .mat-mdc-cell {
          @apply border-solid border-b border-b-light;
        }
      }
    }

    & > tfoot > tr {
      @apply h-auto;

      & > td {
        @apply px-[1rem] py-[0.5rem] align-top;
      }
    }
  }
}
