.cdk-overlay-connected-position-bounding-box .mat-mdc-menu-panel {
  @apply relative top-[1rem];
}

.mat-mdc-menu-panel {
  @apply rounded-[0.5rem] border-none max-w-[33rem];
  @apply shadow-card #{!important};

  .mat-mdc-menu-content {
    @apply bg-primary p-0;

    [mat-menu-item] {
      @apply min-h-[3rem] px-[1rem];

      .mat-icon {
        @apply icon-brand h-[1.5rem] w-[1.5rem] mr-[1rem];
      }
    }
  }
}
