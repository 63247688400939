mat-nav-list {
  mat-list-item,
  [mat-list-item] {
    @apply h-[3rem] px-[1rem] rounded-[0.5rem] items-center #{!important};

    &:focus::before,
    &:hover::before {
      @apply bg-transparent;
    }

    .mat-mdc-list-item-title {
      @apply text-secondary;
    }

    mat-icon {
      @apply mx-0 #{!important};
      @apply icon-secondary;

      &:first-child {
        @apply mr-[0.5rem] #{!important};
      }
    }

    &.mdc-list-item--activated {
      .mat-mdc-list-item-title {
        @apply font-bold text-primary;
      }

      mat-icon {
        @apply icon-brand;

        &:first-child {
          @apply mr-[0.5rem] #{!important};
        }
      }
    }

    &:not(.mdc-list-item--activated) {
      &:hover {
        .mat-mdc-list-item-title {
          @apply text-primary;
        }

        mat-icon {
          @apply icon-primary;
        }
      }
    }
  }
}

mat-sidenav {
  mat-nav-list {
    mat-list-item,
    [mat-list-item] {
      mat-icon {
        &:first-child {
          @apply mr-[1.5rem] #{!important};
        }
      }

      &.mdc-list-item--activated {
        mat-icon {
          &:first-child {
            @apply mr-[1.5rem] #{!important};
          }
        }
      }

      &.mat-badge {
        @apply overflow-hidden #{!important};

        .mat-badge-content {
          @apply left-auto right-[1.75rem] bottom-auto top-[50%] h-[1.5rem] min-w-[1.5rem] w-auto px-[1.5px] rounded-[0.75rem] text-xs text-clip text-inverted-primary;
        }

        &.collapsed {
          .mat-badge-content {
            @apply right-[1rem] top-[0.75rem];
          }
        }
      }
    }

    mat-divider {
      &.mat-divider {
        @apply border-medium my-[1rem];
      }
    }
  }
}
