@use '../../../node_modules/@angular/material/index' as mat;
@use '../../../node_modules/@material/shape/index';

@include mat.core();

$color-accent-brand: rgb(24, 144, 167);
$color-accent-negative: rgb(231, 76, 60);

$color-light-background-primary: rgb(255, 255, 255);
$color-light-background-secondary: rgb(240, 243, 245);
$color-light-stroke-medium: rgb(196, 202, 207);
$color-light-text-primary: rgb(35, 40, 44);
$color-light-text-secondary: rgb(115, 129, 143);
$color-light-text-inverted-primary: rgb(255, 255, 255);

$color-dark-background-primary: rgb(24, 25, 26);
$color-dark-background-secondary: rgb(37, 39, 39);
$color-dark-stroke-medium: rgb(62, 64, 64);
$color-dark-text-primary: rgb(241, 242, 244);
$color-dark-text-secondary: rgb(138, 139, 143);
$color-dark-text-inverted-primary: rgb(24, 25, 26);

$theme-primary: mat.define-palette(
    (
      main: $color-accent-brand,
      lighter: lighten($color-accent-brand, 20%),
      darker: darken($color-accent-brand, 20%),
      200: $color-accent-brand,
      contrast:
      (
        main: $color-light-text-inverted-primary,
        lighter: $color-light-text-primary,
        darker: $color-light-text-inverted-primary,
      ),
    ),
    main,
    lighter,
    darker
);

$theme-warning: mat.define-palette(
    (
      main: $color-accent-negative,
      lighter: lighten($color-accent-negative, 20%),
      darker: darken($color-accent-negative, 20%),
      200: $color-accent-negative,
      contrast:
      (
        main: $color-light-text-inverted-primary,
        lighter: $color-light-text-primary,
        darker: $color-light-text-inverted-primary,
      ),
    ),
    main,
    lighter,
    darker
);

$light-background: (
  background: $color-light-background-primary,
  status-bar: yellow,
  app-bar: cyan,
  hover: navy,
  card: magenta,
  dialog: red,
  tooltip: green,
  disabled-button: lighten($color-accent-brand, 60%),
  raised-button: blue,
  focused-button: orange,
  selected-button: brown,
  selected-disabled-button: pink,
  disabled-button-toggle: yellow,
  unselected-chip: cyan,
  disabled-list-option: navy,
);

$light-foreground: (
  base: $color-light-text-primary,
  divider: $color-light-stroke-medium,
  dividers: $color-light-stroke-medium,
  disabled: $color-light-text-secondary,
  disabled-button: $color-light-text-primary,
  disabled-text: $color-light-text-secondary,
  elevation: yellow,
  secondary-text: $color-light-text-secondary,
  hint-text: cyan,
  accent-text: navy,
  icon: $color-accent-brand,
  icons: $color-accent-brand,
  text: $color-light-text-primary,
  slider-min: yellow,
  slider-off: cyan,
  slider-off-active: navy,
);

$dark-background: (
  background: $color-dark-background-primary,
  status-bar: yellow,
  app-bar: cyan,
  hover: navy,
  card: magenta,
  dialog: red,
  tooltip: green,
  disabled-button: lighten($color-accent-brand, 60%),
  raised-button: blue,
  focused-button: orange,
  selected-button: brown,
  selected-disabled-button: pink,
  disabled-button-toggle: yellow,
  unselected-chip: cyan,
  disabled-list-option: navy,
);

$dark-foreground: (
  base: $color-dark-text-primary,
  divider: $color-dark-stroke-medium,
  dividers: $color-dark-stroke-medium,
  disabled: $color-dark-text-secondary,
  disabled-button: $color-dark-text-primary,
  disabled-text: $color-dark-text-secondary,
  elevation: yellow,
  secondary-text: $color-dark-text-secondary,
  hint-text: cyan,
  accent-text: navy,
  icon: $color-accent-brand,
  icons: $color-accent-brand,
  text: $color-dark-text-primary,
  slider-min: yellow,
  slider-off: cyan,
  slider-off-active: navy,
);

$light-theme: mat.define-light-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-primary,
        warn: $theme-warning,
        background: $light-background,
        foreground: $light-foreground,
      ),
    )
);

$dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-primary,
        warn: $theme-warning,
        background: $dark-background,
        foregrond: $dark-foreground,
      ),
    )
);

:root {
  @include mat.all-component-themes($light-theme);

  &:has(#rb-app.dark) {
    @include mat.all-component-colors($dark-theme);
  }
}

// overrides

:root,
:root:has(#rb-app.dark) {
  @import './mat-button';
  @import './mat-button-toggle';
  @import './mat-checkbox';
  @import './mat-calendar';
  @import './mat-dialog';
  @import './mat-form-field';
  @import './mat-icon';
  @import './mat-menu';
  @import './mat-paginator';
  @import './mat-radio-button';
  @import './mat-selection-list';
  @import './mat-sidenav';
  @import './mat-slide-toggle';
  @import './mat-tab-nav-bar';
  @import './mat-table';
}
