mat-checkbox .mdc-checkbox,
.mat-mdc-list-option .mdc-checkbox {
  @apply w-[3rem] h-[3rem] p-0 flex-[0_0_3rem] #{!important};

  .mat-mdc-checkbox-touch-target {
    @apply w-[3rem] h-[3rem];
  }

  .mdc-checkbox__background {
    @apply w-[2rem] h-[2rem] top-[0.5rem] left-[0.5rem] border-[1px] rounded-[0.25rem];
  }

  .mdc-checkbox__mixedmark {
    @apply w-[1.2rem] h-[1.2rem] border-0 rounded-[0.15rem];
  }

  .mdc-checkbox__native-control {
    @apply w-[3rem] h-[3rem];

    &:not([disabled]) ~ .mdc-checkbox__background {
      @apply bg-primary border-medium #{!important};

      .mdc-checkbox__checkmark {
        @apply text-brand #{!important};
      }

      .mdc-checkbox__mixedmark {
        @apply bg-brand;
      }
    }

    &[disabled] ~ .mdc-checkbox__background {
      @apply bg-secondary border-medium #{!important};

      .mdc-checkbox__checkmark {
        @apply text-tertiary #{!important};
      }

      .mdc-checkbox__mixedmark {
        @apply bg-text-tertiary;
      }
    }
  }

  .mdc-checkbox__ripple,
  .mat-ripple {
    @apply hidden;
  }
}

mat-checkbox {
  .mdc-form-field {
    .mdc-label {
      @apply pl-[0.5rem] text-primary;
    }

    &.mdc-form-field--align-end {
      .mdc-label {
        @apply pl-0 pr-[0.5rem];
      }
    }
  }

  &.mat-mdc-checkbox-disabled {
    .mdc-form-field {
      .mdc-label {
        @apply opacity-40;
      }
    }
  }

  &.vertical {
    .mdc-form-field {
      @apply flex-col;
      .mdc-label {
        @apply px-0 pt-[0.5rem];
      }

      &.mdc-form-field--align-end {
        .mdc-label {
          @apply pt-0 pb-[0.5rem];
        }
      }
    }
  }
}

mat-checkbox:not(disabled) {
  &:hover,
  &:focus {
    .mdc-checkbox__background {
      @apply shadow-input shadow-brand/50;
    }
  }
}
