.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    @apply rounded-[1rem] bg-primary shadow-card;

    .mdc-dialog__title::before {
      display: none;
    }

    .mat-mdc-dialog-actions {
      .mat-mdc-button-base + .mat-mdc-button-base {
        @apply ml-0;
      }
    }
  }
}

.cdk-overlay-dark-backdrop {
  @apply bg-black/50 dark:bg-black/65;
}
