@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-accent-brand: 24 144 167;
    --color-accent-neutral: 115 129 143;
    --color-accent-positive: 26 188 156;
    --color-accent-negative: 231 76 60;
    --color-accent-warning: 230 126 33;
    --color-background-primary: 255 255 255;
    --color-background-secondary: 240 243 245;
    --color-background-tertiary: 221 227 231;
    --color-stroke-light: 221 227 231;
    --color-stroke-medium: 196 202 207;
    --color-stroke-dark: 153 157 159;
    --color-text-primary: 35 40 44;
    --color-text-secondary: 115 129 143;
    --color-text-tertiary: 178 190 203;
    --color-text-inverted-primary: 255 255 255;
    --color-text-inverted-secondary: 221 227 231;
    --color-external-excel: 16 123 65;
    --color-external-pdf: 244 15 2;
    --color-external-word: 22 90 190;
  }

  :root:has(#rb-app.dark) {
    //--color-accent-brand: 24 144 167;
    --color-accent-neutral: 138 139 143;
    --color-accent-positive: 46 209 177;
    --color-accent-negative: 240 85 77;
    --color-accent-warning: 242 144 58;
    --color-background-primary: 24 25 26;
    --color-background-secondary: 37 39 39;
    --color-background-tertiary: 49 51 51;
    --color-stroke-light: 49 51 51;
    --color-stroke-medium: 62 64 64;
    --color-stroke-dark: 74 77 77;
    --color-text-primary: 241 242 244;
    --color-text-secondary: 138 139 143;
    --color-text-tertiary: 101 102 103;
    --color-text-inverted-primary: 24 25 26;
    --color-text-inverted-secondary: 49 51 51;
    //--color-external-excel: 16 123 65;
    //--color-external-pdf: 244 15 2;
    //--color-external-word: 22 90 190;
  }
}

@layer components {
  .icon-brand {
    path {
      @apply fill-brand;
    }
  }

  .icon-primary {
    path {
      @apply fill-primary;
    }
  }

  .icon-secondary {
    path {
      @apply fill-secondary;
    }
  }

  .icon-tertiary {
    path {
      @apply fill-tertiary;
    }
  }

  .icon-inverted-primary {
    path {
      @apply fill-inverted-primary;
    }
  }

  .icon-inverted-secondary {
    path {
      @apply fill-inverted-secondary;
    }
  }

  .icon-warning {
    path {
      @apply fill-warning;
    }
  }

  .icon-positive {
    path {
      @apply fill-positive;
    }
  }

  .icon-negative {
    path {
      @apply fill-negative;
    }
  }

  .icon-pdf {
    path {
      @apply fill-pdf;
    }
  }

  .icon-excel {
    path {
      @apply fill-excel;
    }
  }

  .icon-word {
    path {
      @apply fill-word;
    }
  }

  .spinner-brand {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-brand;
    }
  }

  .spinner-primary {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-primary;
    }
  }

  .spinner-secondary {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-secondary;
    }
  }

  .spinner-tertiary {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-tertiary;
    }
  }

  .spinner-inverted-primary {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-inverted-primary;
    }
  }

  .spinner-inverted-secondary {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-inverted-secondary;
    }
  }

  .spinner-warning {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-warning;
    }
  }

  .spinner-positive {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-positive;
    }
  }

  .spinner-negative {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-negative;
    }
  }

  .spinner-excel {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-excel;
    }
  }

  .spinner-pdf {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-pdf;
    }
  }

  .spinner-word {
    svg, .mdc-circular-progress__determinate-circle {
      @apply stroke-word;
    }
  }
}

@layer utilities {
  .underline-children {
    :not(.no-underline) {
      text-decoration-line: underline;
    }
  }

  .mono {
    font-family: 'Roboto Mono', ui-monospace, monospace;
  }
}
