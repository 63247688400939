@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@400;700&display=swap');
@import './material';
@import './tailwind';

*,
::before,
::after {
  @apply border-medium;
}

html,
body {
  @apply h-full;
  @apply bg-primary;
}

html {
  font-family: 'Roboto', system-ui, sans-serif;
  font-size: 62.5%;
}

body {
  @apply text-base;
}

.ngx-toastr {
  @apply w-96 shadow-xl #{!important};
}

h1 {
  @apply text-xl font-bold flex gap-[1rem] items-center;
}

h2 {
  @apply font-bold my-[0.5rem];
}

ul {
  @apply list-disc pl-[1.5rem];
}

div.rounded {
  &::-webkit-scrollbar-track,
  &-lg::-webkit-scrollbar-track {
    @apply rounded-r;
  }

  &-b {
    &::-webkit-scrollbar-track,
    &-lg::-webkit-scrollbar-track {
      @apply rounded-br;
    }
  }

  &-t {
    &::-webkit-scrollbar-track,
    &-lg::-webkit-scrollbar-track {
      @apply rounded-tr;
    }
  }
}

:hover.underline {
  .no-underline {
    text-decoration-line: none !important;
  }
}

::-webkit-scrollbar-track {
  @apply bg-primary;
}

::-webkit-scrollbar-thumb {
  @apply border-[0.5rem] border-solid border-light rounded-[0.5rem] bg-primary bg-clip-padding;
}
