mat-form-field {
  &:not(.with-error) {
    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before {
    @apply h-0;
  }

  .mdc-text-field--outlined {
    @apply bg-primary rounded-[0.5rem] px-[1rem];

    input,
    textarea {
      @apply text-primary;

      &::placeholder {
        @apply text-tertiary #{!important};
      }
    }

    .mdc-notched-outline__leading {
      @apply rounded-l-[0.5rem];
    }

    .mdc-notched-outline__trailing {
      @apply rounded-r-[0.5rem];
    }

    &:not(.mdc-text-field--disabled) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-medium;
      }

      &:not(.mdc-text-field--focused):hover {
        @apply shadow-input shadow-brand/50;

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          @apply border-medium;
        }
      }

      &.mdc-text-field--focused {
        @apply shadow-input shadow-brand/50;

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          @apply border-brand border-[1px];
        }
      }
    }

    &.mdc-text-field--disabled {
      @apply bg-secondary;

      input,
      textarea {
        @apply text-secondary;
      }
    }
  }

  &:not(.no-error) .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      @apply border-negative/50;
    }

    &:not(.mdc-text-field--focused):hover {
      @apply shadow-negative/50;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-negative/50;
      }
    }

    &.mdc-text-field--focused {
      @apply shadow-negative/50;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-negative;
      }
    }
  }

  &.no-error .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid {
    &:not(.mdc-text-field--focused):hover {
      @apply shadow-input shadow-brand/50;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-medium;
      }
    }

    &.mdc-text-field--focused {
      @apply shadow-input shadow-brand/50;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-brand border-[1px];
      }
    }
  }

  .mdc-notched-outline__leading {
    @apply rounded-l-[0.5rem];
  }

  .mdc-notched-outline__trailing {
    @apply rounded-r-[0.5rem];
  }

  .mat-mdc-select {
    .mat-mdc-select-value {
      @apply mr-[2rem] text-primary;

      .mat-mdc-select-placeholder {
        @apply text-tertiary;
      }
    }

    &.mat-mdc-select-disabled {
      .mat-mdc-select-value {
        @apply text-secondary;
      }

      .mat-mdc-select-placeholder {
        @apply text-tertiary;
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    @apply h-[2rem];
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    @apply p-0;

    mat-icon.mat-icon {
      @apply h-[1.5rem] w-[1.5rem] p-0 my-[0.25rem] icon-brand;
    }

    [mat-icon-button].mat-mdc-icon-button {
      .mat-mdc-button-persistent-ripple,
      .mat-mdc-button-touch-target,
      .mat-mdc-button-ripple {
        @apply h-[2.5rem] w-[2.5rem];
      }

      mat-icon {
        @apply h-[2rem] w-[2rem] m-0;

        svg {
          @apply h-[2rem] w-[2rem];
        }
      }
    }
  }

  .mat-mdc-form-field-icon-prefix {
    @apply mr-[0.5rem];
  }

  .mat-mdc-form-field-icon-suffix {
    @apply ml-[0.5rem];
  }

  .mat-mdc-form-field-text-prefix {
    @apply self-center;
  }

  .mat-mdc-form-field-infix {
    @apply py-[0.5rem] min-h-[3rem];
  }

  .mat-mdc-select-arrow {
    path {
      @apply fill-brand;
    }
  }

  .mat-mdc-form-field-error-wrapper {
    @apply px-[1rem] py-0;
  }

  mat-error {
    @apply text-sm;
  }

  &:not(.multi-line) {
    .mat-mdc-form-field-infix {
      @apply h-[3rem];

      .mat-mdc-autocomplete-trigger {
        @apply text-ellipsis;
      }

      .mat-mdc-select {
        @apply h-[2rem];
      }
    }
  }
}

body:has(.cdk-overlay-container .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) .mat-mdc-autocomplete-panel),
body:has(.cdk-overlay-container .cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mat-mdc-select-panel) {
  mat-form-field.mat-mdc-form-field-type-mat-input,
  mat-form-field.mat-mdc-form-field-type-mat-select {
    &.mat-focused {
      .mdc-text-field--outlined {
        @apply rounded-b-none;

        .mdc-notched-outline__leading {
          @apply rounded-bl-none border-b-light;
        }

        .mdc-notched-outline__trailing {
          @apply rounded-br-none border-b-light;
        }
      }
    }
  }
}

body:has(.cdk-overlay-container .cdk-overlay-pane.mat-mdc-autocomplete-panel-above .mat-mdc-autocomplete-panel) mat-form-field.mat-mdc-form-field-type-mat-input,
body:has(.cdk-overlay-container .cdk-overlay-pane.mat-mdc-select-panel-above .mat-mdc-select-panel) mat-form-field.mat-mdc-form-field-type-mat-select {
  &.mat-focused {
    .mdc-text-field--outlined {
      @apply rounded-t-none;

      .mdc-notched-outline__leading {
        @apply rounded-tl-none border-t-light;
      }

      .mdc-notched-outline__trailing {
        @apply rounded-tr-none border-t-light;
      }
    }
  }
}

.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel {
  @apply border-x-[1px] border-brand bg-primary shadow-input shadow-brand/50 p-0;

  mat-option {
    @apply min-h-[3rem] px-[1rem];

    &:hover:not(.mdc-list-item--disabled) {
      @apply bg-secondary;
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
      @apply bg-tertiary;
    }

    &.mat-mdc-option .mat-pseudo-checkbox.mat-pseudo-checkbox-minimal {
      @apply ml-[1rem] h-[1.5rem] w-[1.5rem];
    }

    .mdc-list-item__primary-text {
      @apply w-full mr-[3rem];
    }

    &.mdc-list-item--selected .mdc-list-item__primary-text {
      @apply mr-[0.5rem];
    }

    &.contains-mat-select-search {
      @apply mt-0 top-0;

      .mat-select-search-input {
        @apply bg-primary border-y-light h-[2.8rem] leading-[2rem] px-[1rem] py-[0.4rem];
      }

      .mat-select-search-clear {
        @apply right-[0.5rem] top-[0.5rem];

        mat-icon {
          @apply icon-secondary;
        }
      }

      .mat-select-search-no-entries-found {
        @apply py-[0.5rem] text-secondary;
      }
    }
  }

  mat-optgroup {
    .mat-mdc-optgroup-label {
      @apply min-h-[4rem] pt-[1rem] px-[1rem];

      .mdc-list-item__primary-text {
        @apply text-secondary;
      }
    }

    .mat-mdc-option {
      @apply pl-[2rem];
    }
  }
}

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) .mat-mdc-autocomplete-panel,
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mat-mdc-select-panel {
  @apply rounded-b-[0.5rem] border-b-[1px];
}

.cdk-overlay-pane.mat-mdc-autocomplete-panel-above .mat-mdc-autocomplete-panel,
.cdk-overlay-pane.mat-mdc-select-panel-above .mat-mdc-select-panel {
  @apply rounded-t-[0.5rem] border-t-[1px];
}

body:has(mat-form-field:not(.no-error) .mdc-text-field--outlined.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled)) {
  .mat-mdc-autocomplete-panel,
  .mat-mdc-select-panel {
    @apply border-negative bg-primary shadow-input shadow-negative/50 p-0;
  }
}
