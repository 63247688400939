mat-selection-list.mat-mdc-selection-list {
  @apply py-[0.5rem];

  .mat-mdc-list-option {
    @apply p-0;
    @apply h-auto #{!important};

    &::before {
      @apply rounded-[0.5rem];
    }

    &:not(.mdc-list-item--selected):focus::before {
      @apply bg-transparent;
    }

    .mdc-list-item__start {
      @apply m-0;
    }
  }
}
