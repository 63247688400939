mat-radio-button {
  .mdc-radio {
    @apply w-[3rem] h-[3rem] p-0 flex-[0_0_3rem];

    .mat-mdc-radio-touch-target {
      @apply w-[3rem] h-[3rem];
    }

    .mdc-radio__background {
      @apply w-[2rem] h-[2rem] top-[0.5rem] left-[0.5rem];

      .mdc-radio__outer-circle {
        @apply border-[1px] border-medium #{!important};
      }

      .mdc-radio__inner-circle {
        @apply border-none bg-brand opacity-100;
      }
    }

    .mdc-radio__native-control {
      @apply w-[3rem] h-[3rem];

      &:checked + .mdc-radio__background {
        .mdc-radio__inner-circle {
          @apply scale-[0.6];
        }
      }
    }

    .mdc-radio__background::before,
    .mat-ripple {
      @apply hidden;
    }

    &.mdc-radio--disabled {
      .mdc-radio__background {
        .mdc-radio__outer-circle {
          @apply bg-secondary;
        }

        .mdc-radio__inner-circle {
          @apply bg-text-tertiary opacity-100 #{!important};
        }
      }
    }

    &:not(.mdc-radio--disabled) {
      &:hover,
      &:focus {
        .mdc-radio__background {
          .mdc-radio__outer-circle {
            @apply shadow-input shadow-brand/50;
          }
        }
      }

      + .mdc-label {
        @apply cursor-pointer;
      }
    }
  }

  .mdc-form-field {
    .mdc-label {
      @apply pl-[0.5rem];
    }

    &.mdc-form-field--align-end {
      .mdc-label {
        @apply pl-0 pr-[0.5rem];
      }
    }
  }

  &.vertical {
    .mdc-form-field {
      @apply flex-col;
      .mdc-label {
        @apply px-0 pt-[0.5rem];
      }

      &.mdc-form-field--align-end {
        .mdc-label {
          @apply pt-0 pb-[0.5rem];
        }
      }
    }
  }
}
