mat-slide-toggle,
mat-slide-toggle:active {
  .mdc-switch {
    @apply w-[4rem];

    .mdc-switch__track {
      @apply h-[2rem] rounded-[1rem];

      &:before,
      &:after {
        @apply bg-primary border-medium;
      }
    }

    .mdc-switch__handle-track {
      .mdc-switch__handle {
        @apply w-[1.6rem] h-[1.6rem] rounded-[0.8rem] mx-[0.2rem];

        &:before,
        &:after {
          @apply bg-text-secondary border-none;
        }

        .mdc-switch__shadow {
          @apply hidden;
        }

        .mdc-switch__ripple {
          @apply w-[2rem] h-[2rem];
        }

        .mdc-switch__icons {
          .mdc-switch__icon {
            @apply w-[1.6rem] h-[1.6rem];
          }
        }
      }
    }

    &:enabled {
      .mdc-switch__track {
        &:before {
          @apply bg-primary;
        }
      }

      &:hover:not(:focus):not(:active),
      &:active,
      &:focus {
        .mdc-switch__track {
          @apply shadow-input shadow-brand/50;
          &:before {
            @apply bg-primary;
          }
        }

        .mdc-switch__handle-track {
          .mdc-switch__handle {
            &:after {
              @apply bg-text-secondary;
            }
          }
        }
      }

      &.mdc-switch--selected,
      &.mdc-switch--selected:hover:not(:focus):not(:active),
      &.mdc-switch--selected:active,
      &.mdc-switch--selected:focus {
        .mdc-switch__handle-track {
          .mdc-switch__handle {
            &:after {
              @apply bg-brand;
            }
          }
        }
      }
    }
  }

  .mdc-label {
    @apply text-secondary pl-[1rem];
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-label {
      @apply text-primary;
    }
  }

  .mdc-switch--disabled {
    .mdc-switch__track {
      @apply opacity-100;

      &:before,
      &:after {
        @apply bg-secondary border-medium;
      }
    }

    .mdc-switch__handle-track {
      .mdc-switch__handle {
        &:after {
          @apply bg-text-tertiary opacity-100;
        }
      }
    }

    & + label {
      @apply text-tertiary;
    }
  }
}
