[mat-button],
[mat-flat-button],
[mat-icon-button] {
  &:disabled {
    @apply opacity-40;
  }

  &:not(:disabled) {
    &:hover,
    &.cdk-focused {
      .if-inactive {
        display: none;
      }
    }

    &:not(:hover):not(.cdk-focused) {
      .if-active {
        display: none;
      }
    }
  }
}

[mat-button] {
  @apply h-[2rem] min-w-0;
  @apply px-[0.5rem] py-0 #{!important};

  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-touch-target,
  .mat-mdc-button-ripple {
    @apply h-[3rem] rounded-[0.5rem] top-[50%] translate-y-[-50%];
  }

  mat-icon {
    @apply h-[1.5rem] w-[1.5rem] p-0 my-[0.25rem] ml-0 mr-[0.5rem];
  }

  mat-spinner {
    @apply inline-block relative align-top my-[0.25rem] ml-0 mr-[0.5rem];
  }

  &.button-primary {
    @apply text-brand;

    mat-icon {
      @apply icon-brand;
    }

    mat-spinner {
      @apply spinner-brand;
    }
  }

  &.button-secondary {
    @apply text-secondary;

    mat-icon {
      @apply icon-secondary;
    }

    mat-spinner {
      @apply spinner-secondary;
    }
  }

  &.button-positive {
    @apply text-positive;

    mat-icon {
      @apply icon-positive;
    }

    mat-spinner {
      @apply spinner-positive;
    }
  }

  &.button-negative {
    @apply text-negative;

    mat-icon {
      @apply icon-negative;
    }

    mat-spinner {
      @apply spinner-negative;
    }
  }

  &.button-excel {
    @apply text-excel;

    mat-icon {
      @apply icon-excel;
    }

    mat-spinner {
      @apply spinner-excel;
    }
  }

  &.button-pdf {
    @apply text-pdf;

    mat-icon {
      @apply icon-pdf;
    }

    mat-spinner {
      @apply spinner-pdf;
    }
  }

  &.button-word {
    @apply text-word;

    mat-icon {
      @apply icon-word;
    }

    mat-spinner {
      @apply spinner-word;
    }
  }

  &.button-sm {
    @apply text-sm;
  }
}

[mat-flat-button] {
  @apply h-[3rem] min-w-0 rounded-[0.5rem] px-[1rem] py-[0.5rem];

  .mdc-button__label {
    @apply text-inverted-primary;
  }

  .mat-mdc-button-touch-target {
    @apply h-[3rem];
  }

  mat-icon {
    @apply h-[1.5rem] w-[1.5rem] p-0 my-[0.25rem] ml-0 mr-[1rem] icon-inverted-primary;
  }

  mat-spinner {
    @apply inline-block relative align-top my-[0.25rem] ml-0 mr-[1rem] spinner-inverted-primary;
  }

  &:not(:disabled):hover {
    @apply shadow-button;
  }

  &.button-primary {
    @apply bg-brand;

    &:not(:disabled):hover {
      @apply shadow-brand;
    }
  }

  &.button-secondary {
    @apply bg-neutral;

    &:not(:disabled):hover {
      @apply shadow-neutral;
    }
  }

  &.button-warning {
    @apply bg-warning;

    &:not(:disabled):hover {
      @apply shadow-warning;
    }
  }

  &.button-negative {
    @apply bg-negative;

    &:not(:disabled):hover {
      @apply shadow-negative;
    }
  }
}

[mat-icon-button].mat-mdc-icon-button:not(.mat-calendar-previous-button):not(.mat-calendar-next-button) {
  @apply h-[2rem] w-[2rem] p-0;

  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-touch-target,
  .mat-mdc-button-ripple {
    @apply h-[3rem] w-[3rem] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
  }

  mat-icon {
    @apply h-[2rem] w-[2rem];

    svg {
      @apply h-[2rem] w-[2rem];
    }
  }

  &.button-primary {
    mat-icon {
      @apply icon-brand;
    }
  }

  &.button-secondary {
    mat-icon {
      @apply icon-secondary;
    }
  }
}
