mat-icon {
  &.mat-icon {
    &.icon-10 {
      @apply h-[1rem] w-[1rem];

      svg {
        @apply h-[rem] w-[1rem];
      }
    }

    &.icon-15 {
      @apply h-[1.5rem] w-[1.5rem];

      svg {
        @apply h-[1.5rem] w-[1.5rem];
      }
    }

    &.icon-20 {
      @apply h-[2rem] w-[2rem];

      svg {
        @apply h-[2rem] w-[2rem];
      }
    }

    &.icon-40 {
      @apply h-[4rem] w-[4rem];

      svg {
        @apply h-[4rem] w-[4rem];
      }
    }
  }
}

.flex > mat-icon {
  @apply shrink-0;
}
