mat-paginator {
  @apply bg-transparent text-primary;

  .mat-mdc-paginator-container {
    @apply min-h-[3rem] p-0;

    .mat-mdc-paginator-page-size {
      @apply m-0 gap-[0.5rem];

      .mat-mdc-paginator-page-size-label,
      .mat-mdc-paginator-page-size-select {
        @apply m-0;
      }
    }

    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-range-label {
        @apply mx-[2rem];
      }

      .mat-mdc-icon-button {
        svg {
          @apply fill-brand;
        }
      }
    }
  }

  .mat-mdc-select {
    @apply leading-[2rem];
  }
}
