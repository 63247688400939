[mat-tab-nav-bar] {
  .mat-mdc-tab-header-pagination {
    @apply border-solid border-b border-t-0 border-x-0 border-b-medium;
  }

  .mat-mdc-tab-links {
    @apply gap-[4rem] border-b border-b-medium;

    .mat-mdc-tab-link:not(.mdc-tab--stacked) {
      @apply h-[3rem] p-0 min-w-0;

      &:focus,
      &:hover {
        .mdc-tab__ripple::before {
          @apply bg-transparent;
        }
      }

      &:not(.mdc-tab--active) {
        .mdc-tab__text-label {
          @apply text-secondary;
        }

        &:focus,
        &:hover {
          .mdc-tab__text-label {
            @apply text-primary;
          }

          .mdc-tab-indicator__content--underline {
            @apply border-text-primary border-t-[3px] translate-y-[1px] opacity-100 transition-none;
          }
        }
      }

      &.mdc-tab--active {
        .mdc-tab__text-label {
          @apply font-bold text-primary;
        }

        .mdc-tab-indicator__content--underline {
          @apply border-brand border-t-[3px] translate-y-[1px];
        }
      }
    }
  }
}
