mat-calendar {
  &.limited-picker {
    .mat-calendar-period-button {
      pointer-events: none;
    }

    .mat-calendar-arrow {
      display: none;
    }
  }
}
